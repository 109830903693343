<style>

</style>
<script>
import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {formatBytes, get_acsrf_token} from "@/methods";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    VclList,
    VclBulletList,
    Protocol: () => import('@/components/lazy/banmanager/view/desktop/ProtocolSmall'),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    formatBytes(b) {
      return formatBytes(b);
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.banlist = data.banlist;
          this.gryphon = data.banlist.gryphon;
          if(this.gryphon !== null) {
            let usage = Number(((this.gryphon.storage.aggregated_size / this.gryphon.storage.quota)*100)).toFixed(1);
            this.gryphonSeries = this.gryphon.storage.quota <= 0 ? [0] : [usage];
            this.gryphonOptions['colors'] = usage > 90 ? ['#f46a6a'] : ['#34c38f'];
            if(this.gryphon.storage.quota === 0) {
              this.gryphonOptions.plotOptions.radialBar.track.background = '#34c38f';
            }
          }
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async onToggleOption(event) {
      this.transmission.unlisted = true;
      setTimeout(() => {
        this.updateListing(event.value);
      }, 750);
    },
    async updateListing(value) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          value: !value
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/listing`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          // Ok
        } else {
          if(response.status === 429) {
            this.banlist.meta.unlisted = !value;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.banlist.meta.unlisted = !value;
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.transmission.unlisted = false;
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      banlist: null,
      transmission: {
        unlisted: false,
      },
      gryphon: {},
      gryphonSeries: {},
      gryphonOptions: {
        chart: {
          height: 150,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#556ee6"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#000000",
              strokeWidth: "100%",
              margin: 5,
            },
            hollow: {
              size: "60%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "16px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -15,
          },
        },
        labels: [this.$t('dashboard.gryphon.storage')],
      },
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(banlist.metrics.bans) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('banmanager.view.overview.bans') }}
                      </p>
                    </div>
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(banlist.metrics.followers) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('banmanager.view.overview.followers') }}
                      </p>
                    </div>
                    <div class="col-lg">
                      <div class="h-100 mt-3">
                        <div class="row ">
                          <div class="col text-center">
                            <h4 class="text-uppercase d-inline">
                              {{ $t('banmanager.view.overview.code') }}
                            </h4>
                            <h5 class="ml-2 d-inline">
                              <CopyableText :text="banlist.uuid" class="text-code" />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Row two -->
          <div class="row">
            <div class="col-lg-6" v-if="ready && options.permissions.protocol === true">
              <Protocol :banlist_id="banlist_id"/>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body" style="min-height: 337px;" v-if="gryphon">
                  <div>
                    <h5 class="p-0 text-center">
                      <i class="fas fa-database" />
                      {{ $t('dashboard.gryphon.title') }}
                    </h5>
                    <apexchart
                        class="apex-charts mt-3"
                        type="radialBar"
                        height="150"
                        dir="ltr"
                        :series="gryphonSeries"
                        :options="gryphonOptions"
                    ></apexchart>
                    <div class="text-muted mt-2 text-center">
                      <b>
                        {{ formatBytes(gryphon.storage.aggregated_size || 0) }}
                        /
                        <template v-if="gryphon.storage.quota <= 0">
                          Ꝏ
                        </template>
                        <template v-else>
                          {{ formatBytes(gryphon.storage.quota) }}
                        </template>
                        {{ $t('dashboard.gryphon.used') }}
                      </b>
                    </div>
                  </div>
	                <!--
                  <div>
                    <template v-if="banlist.meta.listed">
                      <hr>
                      <template v-if="banlist.meta.delisted">
                        <h4 class="m-0 p-0">Public Listing</h4>
                        <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-warning mt-4">
                          <div>
                            <h5 class="text-black"><i class="fad fa-times-hexagon"/> Delisted</h5>
                            <p class="text-black">
                              Your ban list has been permanently delisted.
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <h4 class="m-0 p-0">Public Listing <small class="font-size-12 badge badge-success text-black"><i class="fad fa-badge-check"/> You are eligable</small> </h4>
                        <div class="row mt-4">
                          <div class="w-75">
                            <div class="ml-4 mr-4">
                              <h4>
                                List Publicly
                              </h4>
                              <span>
                              Your ban entries will be shown publicly.<br>
                              <a href="https://help.cftools.com/cf-tools-cloud/public-listing" target="_blank">
                                Review before enabling <i class="fad fa-external-link-square"/>
                              </a>
                            </span>
                            </div>
                          </div>
                          <div class="w-25">
                            <toggle-button ref="metaUnlisted" v-model="banlist.meta.unlisted" @change="onToggleOption($event)"
                                           :disabled="transmission.unlisted"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.metaUnlisted && $refs.metaUnlisted.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>

                      </template>

                    </template>
                  </div>
                  -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row">
                <div class="col-lg-6">

                </div>
                <div class="col-lg-6">

                </div>
              </div> -->
      </div>
    </template>
  </div>
</template>
